(function ($) {
	$('#fullpage').fullpage({
		anchors: ['home', 'contact'],
		controlArrows: false,
		onLeave: onLeave
	});

	$('#contact-form').validate({
		errorElement: 'div',
		submitHandler: function (form, event) {
			event.preventDefault();

			$(form).ajaxSubmit({
				dataType: 'json',
				beforeSubmit: function (formData, form, options) {
					form.find('input, textarea, button').attr('disabled', 'disabled');
				},
				complete: function () {},
				success: function (data) {
					if (data.error) {
						alert(data.message);
						form.find('input, textarea, .btn').prop('disabled', false);
					} else {
						$(form).hide();
						$(form).next('.form-response-message').show();
						// $('html, body').animate({
						// 	scrollTop: $(form).next('.form-success-message').offset().top - 80
						// }, 500);
					}
				},
				error: function (error) {
					console.log(error);
				}
			});
		}
	});

	function onLeave(index, nextIndex, direction) {
		if (index == 1 && nextIndex == 2) {
			setTimeout(function () {
				$('#name').focus();
			}, 1100);
		}
	}
})(jQuery);